import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import SellingPoint from "../components/SellingPoint/sellingPoint"
import { main } from "../styles/theme"

const HeadingWrapper = styled.div`
  margin: 46px 0 0;

  @media ${main.breakpoints.large} {
    margin: 196px 0 0;
  }
`
const SellingPoints = styled.ul`
  padding: 90px 0 100px;
  margin: 0;
  list-style-type: none;
`

const CustomH1 = styled.h1`
  font-size: 18.5vw;
  line-height: 0.5;
  letter-spacing: -0.02em;
  font-weight: normal;
  margin: 0;
  white-space: break-spaces;

  @media ${main.breakpoints.small} {
    font-size: 19.5vw;
    line-height: 0.6;
  }
  @media ${main.breakpoints.medium} {
    font-size: 20.5vw;
    line-height: 0.6;
  }

  @media ${main.breakpoints.xlarge} {
    font-size: 21.5vw;
    line-height: 0.7;
  }
`

const Funktioner = () => (
  <Layout>
    <Seo title="Funktioner" />
    <HeadingWrapper>
      <CustomH1>Funktioner</CustomH1>
    </HeadingWrapper>
    <StaticImage
      src="../images/newhouse.jpg"
      quality={95}
      layout="fullWidth"
      formats={["AUTO", "WEBP"]}
      alt="Funktioner"
      style={{ marginTop: `2.45rem` }}
    />
    <SellingPoints>
      <SellingPoint
        listNumber="(00)"
        title="Terrassfönster"
        text={[
          "De unika fönstren Svalson Open på Gårdshuset är ett helt nytt, vertikalgående och eldrivet fönstersystem. Med sina glasytor från golv till tak och vägg till vägg ges rummet maximalt ljusinsläpp och möjligheten att nyttja rummets hela golvyta då inga dörrar eller fönster behöver öppnas inåt och förstöra möbleringen, allt för att kunna maximera rummets användbarhet och kvadratmeter.",
          "Fönstret består av ett fast nedre glasparti som är vikbart inåt för enkel rengöring och ett vertikalt rörligt övre glasparti. Det övre glaset är steglöst öppningsbart till valfri höjd och automatiskt låst i stängt läge samt i vädringsläge. Öppning och stängning sker enkelt via kontrollpanel eller fjärrkontroll och kan även kopplas till trådlös Smarta hem-lösning.",
          "Glaset har ett U-värde på 0,6 W/m2K och en ljudreduktion på 39dB vilket både ger låg energiförbrukning och tysthet de dagar du vill stänga ute stadens puls och njuta av lugn och ro. Öppnar du i stället fönstren maximalt och släpper in den ljumma sommarkvällen förvandlar du hela lägenheten till en terrass.",
          "Gäller endast de raka glaspartierna.",
        ]}
      />
      <SellingPoint
        listNumber="(01)"
        title="Nyckelfritt"
        text={[
          "Lägenhetens ytterdörr skyddas av ett elektromekaniskt säkerhetslås från Yale Doorman. Du öppnar och låser med kod, tag eller mobilapp. Glöm alla borttappade, gömda eller utlånade nycklar. Låset är godkänt av Svenska Stöldskyddsföreningen i klass 3. Lägenhetens porttelefon hanterar du via mobilen. Det gör det enkelt att ta emot ett bud eller släppa in en kompis – var du än är.",
        ]}
      />
      <SellingPoint
        listNumber="(02)"
        title="Självständig konsumtion"
        text={[
          "Vi tror på den medvetna människan och varje individs påverkan på vår miljö. Genom individuell mätning av vatten och el blir det möjligt för varje lägenhet att påverka och anpassa sitt användande, och i förlängningen både sin kostnad och miljöpåverkan. Detta utan att belastas av andras överkonsumtion.",
          "Denna medvetenhet hoppas vi kommer bidra till hela föreningens ekologiska fotavtryck. Den enskilda medlemmen ges möjlighet till eget ansvar och ett flexibelt liv. Är du bortrest en längre period kan driftkostnaden och miljöpåverkan sänkas till minimala nivåer. För den som uppskattar att släppa in den kalla kvällsbrisen med öppna fönster eller en långa duschar, påverkar det endast den egna energiförbrukningen och inte övriga grannar.",
          "Fastigheten ger dig goda förutsättningar i form av bland annat 85-procentig värmeväxling i ventilationen, solceller på taket samt varmvatten med instant heating, vilket minimerar energislösande cirkulation av varmvatten. Tillsammans med personligt ansvar ger det här optimala möjligheter till en mer hållbar förbrukning.",
        ]}
      />
      <SellingPoint
        listNumber="(03)"
        title="Solceller"
        text={[
          "På taket utanför det vackra glasräcket monteras solceller för gemensam elproduktion. Det höga och fria läget gör det gynnsamt och den installerade effekten beräknas bli ca 20 kWp, vilket innebär drygt 0,5 kWp per lägenhet och en årlig produktion på ungefär 17 MWh/år",
        ]}
      />
      <SellingPoint
        listNumber="(04)"
        title="Cykelparkering och service"
        text={[
          "Det centrala läget gör att du med cykel når det mesta i innerstaden på bara några minuter. Allt talar alltså för att Smedjan kommer att befolkas av medvetna cyklister och det ska vara enkelt att färdas miljövänligt. Därför kommer allt att vara förberett för såväl säker och smart cykelförvaring, ladduttag, avsedd plats för cykelfix med vatten för avspolning och möjlighet att pumpa och serva sin cykel.",
        ]}
      />
      <SellingPoint
        listNumber="(05)"
        title="Kostnadsfri kollektivtrafik i 30 dagar och ett års lånecykel"
        text={[
          "Vi tror även på kollektivtrafiken ur hållbarhetssynpunkt. Järntorget är en närbelägen knutpunkt och för att komma igång får du som medlem i föreningen gratis kollektivtrafik under 30 dagar. Dessutom får du ett årskort på Göteborgs lånecyklar Styr & Ställ.",
        ]}
      />
      <SellingPoint
        listNumber="(06)"
        title="Attraktivt medlemskap i Bilpool"
        text={[
          "Ibland räcker varken cykel eller kollektivtrafik. Som boende erbjuds du ett attraktivt medlemskap i en bilpool. Det gagnar både miljön och din ekonomi.",
        ]}
      />
      <SellingPoint
        listNumber="(07)"
        title="Digital anslagstavla – realtidsinformation om kollektivtrafik"
        text={[
          "Föreningens kommunikationskanal är den digitala anslagstavlan för allt från gårdsfester till viktig information. Här kommer du också få realtidsinformation om kollektivtrafiken.",
        ]}
      />
      <SellingPoint
        listNumber="(08)"
        title="Miljöstation"
        text={[
          "Föreningens miljöstation kommer att vara extra genomtänkt, i linje med hållbarhetsambitionen. Vi planerar för källsortering av papper, plast, glas, metall, matavfall och brännbart avfall.",
        ]}
      />
    </SellingPoints>
  </Layout>
)

export default Funktioner
