import React from "react"
import PropTypes from "prop-types"
import { Wrapper, ListNumber, InfoText } from "./styles"
import { H3, H2, P } from "../../styles/typography"

const SellingPoint = ({ listNumber, title, text, subTitle, html }) => {
  return (
    <Wrapper>
      <ListNumber>
        <H3>{listNumber}</H3>
      </ListNumber>
      <InfoText>
        <H2>{title}</H2>
        {subTitle && <H3>{subTitle}</H3>}
        {text && text.map((section, index) =>
          <P key={index} size="large">{section}</P>
        )}
        {html && html}
      </InfoText>
    </Wrapper>
  )
}
SellingPoint.propTypes = {
  listNumber: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  text: PropTypes.array,
  html: PropTypes.node
}

SellingPoint.defaultProps = {
  listNumber: "",
  title: "",
  subTitle: "",
  text: null,
  html: null
}

export default SellingPoint
